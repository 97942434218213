import React from "react";
import Particles from "react-particles-js";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from "gatsby"


const useStyles = makeStyles(theme => ({
  particles: {
    position: 'relative',
    width: '100%',
    height: "100vh",
    backgroundSize: 'cover',
    backgroundColor: '#222534',
     
  }
}));

const particleStyles = {
  "particles": {
    "number": {
        "value": 120,
        "density": {
            "enable": false
        }
    },
    "size": {
        "value": 3,
        "random": true,
        "anim": {
            "speed": 4,
            "size_min": 0.3
        }
    },
    "line_linked": {
        "enable": false
    },
    "move": {
        "random": true,
        "speed": 1,
        "direction": "top",
        "out_mode": "out"
    }
  },
  "interactivity": {
      "events": {
          "onhover": {
              "enable": true,
              "mode": "bubble"
          },
          "onclick": {
              "enable": true,
              "mode": "repulse"
          }
      },
      "modes": {
          "bubble": {
              "distance": 250,
              "duration": 2,
              "size": 0,
              "opacity": 0
          },
          "repulse": {
              "distance": 400,
              "duration": 4
          }
      }
    }
  };

export default () => {

  const classes = useStyles();
  return (
    
      <Particles params={particleStyles} height={"100vh"} className={classes.particles}/>
   
  )
}
